<template>
  <mobile-screen
    :header="true"
    :screen-class="
      [
        'r_plan-slots-help-online',
        'r_plan-resources-slots-help-online'
      ].includes($route.name)
        ? 'gray-bg'
        : 'gray-bg show-footer-only-on-last-screen'
    "
  >
    <template v-slot:header>
      <top-header-menu-wrapper
        menu-class="resource-header icon-hea1"
        :helpOnline="
          Boolean(
            helpOnline[helpSlug] &&
              helpOnline[helpSlug].name &&
              helpOnline[helpSlug].content
          )
        "
        :helpOnlineRoute="{
          name: helpLinkName
        }"
      >
        <template v-slot:left>
          <button @click="backButton">
            <icon icon="#cx-hea1-arrow-left" />
          </button>
        </template>
        <div class="component-title">
          <template v-if="selectedResource">
            {{ selectedResource.name }}
          </template>
          <template v-else-if="selectedFrequentResourceType">
            {{ selectedFrequentResourceType.name }}
          </template>
          <template
            v-else-if="
              selectedFilters &&
                selectedFilters.length &&
                selectedFilters.length > 1
            "
          >
            {{ displayLabelName("plan", "plan", "location") }}
          </template>
          <template v-else-if="selectedFilters && selectedFilters.length">
            {{ selectedFilters[0].name }}
          </template>
          <template v-else-if="selectedLevel">
            {{ selectedLevel.name }}
          </template>
          <template v-else>
            {{ displayLabelName("plan", "plan", "location") }}
          </template>
        </div>
      </top-header-menu-wrapper>
    </template>
    <div class="clebex-item-section">
      <div class="clebex-item-section-item overflow-hidden">
        <space-calendar
          :select-date-fn="selectDate"
          :select-month-fn="selectMonth"
          :occupied-dates="occupancy && occupancy.data"
          :disable-previous-days="true"
          :selectedDate="selectedDate"
        ></space-calendar>
      </div>
    </div>
    <div class="clebex-item-section" v-if="slots">
      <select-slots
        :slots="slots"
        :preselected-slot="preselectedSlot"
        :show-percentage="!!selectedResource"
      ></select-slots>
    </div>
    <template v-slot:footer>
      <plans-footer-menu>
        <button
          :disabled="disabledButton || !validDuration"
          @click="
            showModal = true;
            if (selectedSlot.occupancy_percentage === 100) modalData.name = '';
            if (this.selectedResource) {
              this.planDeclarationCost(modalData.declarationCost);
              this.getAlternativeResource();
            }
          "
        >
          <icon
            icon="#cx-foo2-alternative-38x38"
            v-if="
              selectedResource &&
                selectedSlot &&
                selectedSlot.occupancy_percentage &&
                selectedSlot.occupancy_percentage === 100
            "
          />
          <icon v-else icon="#cx-foo2-submit-38x38" />
        </button>
      </plans-footer-menu>
    </template>
    <screen-modal
      v-if="showModal"
      class="confirm-modal"
      type="success"
      :confirm-button-label="displayLabelName('plan', 'plan', 'ok')"
      :cancel-button-label="displayLabelName('plan', 'plan', 'cancel')"
      :confirm-action="declareSlot"
      :show="showModal"
      @close="showModal = false"
    >
      <h3 class="modal-title">
        {{ displayLabelName("plan", "plan", "declaration-confirmed") }}
      </h3>
      <p class="text" v-if="cost">
        {{ displayLabelName("plan", "plan", "cost") }}: {{ cost }}
      </p>
      <template v-if="modalData">
        <p class="text">
          <time
            v-if="['DAY', 'HALF_DAY'].includes(modalData.type)"
            :datetime="modalData.datetime_from"
            >{{ modalData.datetime_from }}</time
          >
          <time
            v-else
            :datetime="`${modalData.datetime_from} - ${modalData.datetime_to}`"
            >{{ modalData.datetime_from }} - {{ modalData.datetime_to }}</time
          >
        </p>
        <p class="text">{{ modalData.name }}</p>
      </template>
    </screen-modal>
  </mobile-screen>
  <router-view></router-view>
</template>

<script>
import MobileScreen from "@/layouts/MobileScreen";
import TopHeaderMenuWrapper from "@/components/global/TopHeaderMenuWrapper";
import PlansFooterMenu from "@/components/plans/PlansFooterMenu";
import SpaceCalendar from "@/components/plans/SpaceCalendar";
import SelectSlots from "@/components/plans/SelectSlots";
import { mapActions, mapGetters, mapState } from "vuex";
import httpServiceAuth from "@/services/http-service";
import { apiEndpoints } from "@/services/constants";
import { errorHandler } from "@/services/error-handler";
import {
  displayDateWithTimezone,
  getOffsetFromDateString,
  getTimezoneCodeFromOffset
} from "@/services/helpers";
import PlanMixin from "@/services/mixins/plan/plan-mixin";
import { DateTime } from "luxon";
import { subscribe, unsubscribe } from "@/services/ws-service";
import { wsEvents } from "@/services/constants";
import helpOnlineMixin from "@/services/mixins/help_online/help-online-mixin";

export default {
  name: "PlanSlots",
  data() {
    return {
      selectedDate: null,
      selectedMonth: null,
      showModal: false,
      preselectedSlot: "",
      channel: null,
      helpSlug: "plan-resources-calendar",
      dateTimeFrom: null,
      dateTimeTo: null,
      alternativeResource: null
    };
  },
  created() {
    if (!this.selectedLevel && !this.selectedResource) {
      let routeName = "";
      if (this.$route.name.startsWith("r_one-click")) {
        routeName = "r_one-click";
      } else {
        routeName = "r_plan";
      }
      this.$router.push({
        name: routeName
      });
    }
    // reset data
    // selected slot
    this.$store.commit("plan/setSelectedSlot", null, { root: true });

    // occupancy and slots data
    this.resetSlotsAndOccupancy();

    if (this.selectedResource) {
      this.channel = subscribe(
        "declaration-resource",
        [this.selectedResource.id],
        wsEvents.declaration.changed,
        this.wsCallback
      );
    }
  },
  mounted() {
    this.handlePreselectedDate();
  },
  computed: {
    ...mapState("plan", [
      "slots",
      "occupancy",
      "selectedSlot",
      "resourcesLimit",
      "cost"
    ]),
    ...mapState("resource", ["resourceModels", "resourceTypes"]),
    ...mapState("searchFiltersPlanResources", [
      "selectedTypeIds",
      "selectedModelIds"
    ]),
    ...mapState("timezone", ["timezones"]),
    ...mapState("oneClick", ["setPlan"]),
    ...mapGetters("settings", ["globalDateFormat", "globalTimeFormat"]),
    selectedFilters() {
      let filters = [];
      const {
        resourceTypes,
        selectedTypeIds,
        resourceModels,
        selectedModelIds
      } = this;
      if (
        resourceTypes &&
        resourceTypes.data &&
        resourceTypes.data.length &&
        selectedTypeIds &&
        selectedTypeIds.length
      ) {
        const filtered = resourceTypes.data.filter(
          item => selectedTypeIds.indexOf(item.id) !== -1
        );
        filters = [...filters, ...filtered];
      }

      if (
        resourceModels &&
        resourceModels.data &&
        resourceModels.data.length &&
        selectedModelIds &&
        selectedModelIds.length
      ) {
        const filtered = resourceModels.data.filter(
          item => selectedModelIds.indexOf(item.id) !== -1
        );
        filters = [...filters, ...filtered];
      }

      return filters;
    },
    modalData() {
      const { displayDate } = this;

      if (this.selectedSlot) {
        const { type } = this.selectedSlot;
        let parents = "";
        if (
          this.selectedLevel &&
          this.selectedLevel.all_parents &&
          this.selectedLevel.all_parents.length
        ) {
          parents = `, ${this.displayParentNames(
            this.selectedLevel.all_parents
          )}`;
        }
        let data = {
          ...this.selectedSlot
        };

        if (this.selectedResource) {
          data = {
            ...data,
            ...this.selectedResource
          };
          data.name += `, ${this.selectedLevel.name}${parents}`;
        } else if (this.selectedFrequentResourceType) {
          data = {
            ...data,
            ...this.selectedFrequentResourceType
          };
          data.name += `, ${this.selectedLevel.name}${parents}`;
        } else if (this.selectedFilters && this.selectedFilters.length) {
          data = {
            ...data,
            ...this.selectedFilters[0]
          };
          data.name += `, ${this.selectedLevel.name}${parents}`;
        } else if (this.selectedLevel) {
          data = {
            ...data,
            ...this.selectedLevel
          };
          data.name += `${parents}`;
        }

        if (["DAY", "HALF_DAY"].includes(type)) {
          if (data.slots.length === 1) {
            data.datetime_from_original = data.slots[0].datetime_from;
            data.datetime_from = displayDate(
              data.slots[0].datetime_from,
              this.globalDateFormat || "DD MMMM YYYY"
            );
            data.datetime_to_original = data.slots[0].datetime_to;
            data.datetime_to = displayDate(
              data.slots[0].datetime_to,
              this.globalDateFormat || "DD MMMM YYYY"
            );
          } else {
            data.datetime_from_original = data.slots[0].datetime_from;
            data.datetime_from = displayDate(
              data.slots[0].datetime_from,
              this.globalDateFormat || "DD MMMM YYYY"
            );
            data.datetime_to_original =
              data.slots[data.slots.length - 1].datetime_to;
            data.datetime_to = displayDate(
              data.slots[data.slots.length - 1].datetime_to,
              this.globalDateFormat || "DD MMMM YYYY"
            );
          }
        } else {
          if (data.slots.length === 1) {
            data.datetime_from_original = data.slots[0].datetime_from;
            data.datetime_from = displayDate(
              data.slots[0].datetime_from,
              this.globalTimeFormat || "HH:mm"
            );
            data.datetime_to_original = data.slots[0].datetime_to;
            data.datetime_to = displayDate(
              data.slots[0].datetime_to,
              this.globalTimeFormat || "HH:mm"
            );
          } else {
            data.datetime_from_original = data.slots[0].datetime_from;
            data.datetime_from = displayDate(
              data.slots[0].datetime_from,
              this.globalTimeFormat || "HH:mm"
            );
            data.datetime_to_original =
              data.slots[data.slots.length - 1].datetime_to;
            data.datetime_to = displayDate(
              data.slots[data.slots.length - 1].datetime_to,
              this.globalTimeFormat || "HH:mm"
            );
          }
        }

        if (this.selectedResource) {
          data.declarationCost = {
            resource_id: this.selectedResource.id,
            datetime_from: data.datetime_from_original,
            datetime_to: data.datetime_to_original
          };
        }

        return data;
      }
      return null;
    },
    resourceModelDeclareId() {
      const { resourceModels } = this;
      if (resourceModels && resourceModels.data && resourceModels.data.length) {
        return resourceModels.data.filter(item => item.model === "DECLARE")[0]
          .id;
      }
      return null;
    },
    resourceModelBookingId() {
      const { resourceModels } = this;
      if (resourceModels && resourceModels.data && resourceModels.data.length) {
        return resourceModels.data.filter(item => item.model === "BOOKING")[0]
          .id;
      }
      return null;
    },
    disabledButton() {
      const { selectedSlot, resourcesLimit, selectedDate } = this;
      if (selectedSlot && resourcesLimit) {
        return !selectedDate;
      }
      return !selectedDate || !selectedSlot;
    },
    routeName() {
      return this.$route.name;
    },
    validDuration() {
      if (
        this.selectedSlot &&
        this.selectedSlot.slots &&
        this.selectedSlot.slots.length
      ) {
        let duration = DateTime.fromISO(
          this.selectedSlot.slots.slice(-1)[0].datetime_to
        )
          .diff(
            DateTime.fromISO(this.selectedSlot.slots[0].datetime_from),
            "minutes"
          )
          .as("minutes");
        const { resourcesLimit, selectedResource } = this;
        let minDeclarationDuration = null;
        let maxDeclarationDuration = null;
        if (resourcesLimit && resourcesLimit.data && selectedResource) {
          minDeclarationDuration = resourcesLimit.data.min_declaration_duration;
          maxDeclarationDuration = resourcesLimit.data.max_declaration_duration;
        }
        if (duration) {
          if (
            (minDeclarationDuration && duration < minDeclarationDuration) ||
            (maxDeclarationDuration && duration > maxDeclarationDuration)
          ) {
            return false;
          }
          return true;
        }
        return false;
      }
      return false;
    }
  },
  methods: {
    ...mapActions("plan", [
      "getSlots",
      "getOccupancy",
      "setSelectedSlot",
      "getResourcesLimit",
      "planDeclarationCost"
    ]),
    getAlternativeResource() {
      if (this.selectedSlot.occupancy_percentage === 100) {
        httpServiceAuth
          .get(
            `${apiEndpoints.company.declarations}/resources/${this.selectedResource.id}/alternative`,
            { params: this.selectedSlot }
          )
          .then(response => {
            this.alternativeResource = response.data.data;
            this.modalData.name =
              response.data.data.name + ", " + response.data.data.level.name;
          })
          .catch(error => {
            if (error.response) {
              errorHandler(error.response);
            }
          });
      }
    },
    getRequiredSlots(
      noLoaders = this.routeName === "r_one-click-calendar" ? true : false
    ) {
      if (this.selectedResource) {
        this.getSlots({
          payload: {
            types: ["DAY", "HALF_DAY", "HOUR", "HALF_HOUR", "QUARTER_HOUR"],
            type: "resources",
            id: this.selectedResource.id,
            date: this.selectedDate
          },
          noLoaders: noLoaders
        });
        this.getOccupancy({
          payload: {
            type: "resources",
            date: DateTime.fromISO(this.selectedDate).toFormat("yyyy-MM"),
            id: this.selectedResource.id
          },
          noLoaders: noLoaders
        });
      } else {
        this.getSlots({
          payload: {
            types: ["DAY", "HALF_DAY", "HOUR", "HALF_HOUR", "QUARTER_HOUR"],
            type: "default",
            date: this.selectedDate,
            timezone: DateTime.now().zoneName
          },
          noLoaders: noLoaders
        });
        this.$store.commit("plan/setOccupancyData", null, { root: true });
      }
    },
    selectDate(date) {
      this.selectedDate = date.toFormat("yyyy-MM-dd");
      if (this.selectedResource) {
        this.getResourcesLimit(this.selectedResource.id);
      }
      this.getRequiredSlots();
      setTimeout(() => {
        this.handlePreselectedSlots();
      }, 0);

      if (this.$route.path.indexOf("resources/slots") !== -1) {
        this.$router.push({
          name: "r_plan-resources-slots"
        });
      }
    },
    selectMonth(month, year) {
      const noLoaders =
        this.routeName === "r_one-click-calendar" ? true : false;
      this.selectedMonth = `${year}-${month < 10 ? "0" : ""}${month}`;

      if (this.timeout) clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        this.getOccupancy({
          payload: {
            type: "resources",
            date: this.selectedMonth,
            id: this.selectedResource.id
          },
          noLoaders: noLoaders
        });
      }, 500);

      if (this.$route.path.indexOf("resources/slots") !== -1) {
        this.$router.push({
          name: "r_plan-resources-slots"
        });
      }
    },
    resetSlotsAndOccupancy() {
      this.$store.commit("plan/setSlotsData", null, { root: true });
      this.$store.commit("plan/setOccupancyData", null, { root: true });
    },
    declareSlot() {
      const dataToSend = {
        ...this.selectedSlot
      };

      let type = "";
      if (this.selectedResource) {
        if (this.selectedSlot.occupancy_percentage === 100) {
          type = `/resources/${this.selectedResource.id}/alternative`;
        } else {
          type = `/resources/${this.selectedResource.id}`;
        }
      } else if (this.selectedFrequentResourceType) {
        type = "/resources";
        dataToSend.resource_ids = [this.selectedFrequentResourceType.id];
      } else if (this.anyResourceTypes && this.anyResourceTypes.length) {
        type = "/resources";
        dataToSend.resource_ids = this.anyResourceTypes;
      } else if (this.selectedLevel) {
        type = `/levels/${this.selectedLevel.id}`;
      }

      delete this.selectedSlot.occupancy_percentage;

      if (this.selectedFrequentResourceType) {
        const params = { mode: "INDIRECT" };
        if (this.resourceModelDeclareId && this.resourceModelBookingId) {
          params.resource_model_ids = [
            this.resourceModelDeclareId,
            this.resourceModelBookingId
          ];
        }
        this.$store.commit("loader/setScreenLoading", true, { root: true });

        return httpServiceAuth
          .get(
            `${apiEndpoints.company.levels}/${this.selectedLevel.id}/resources/all`,
            {
              params: {
                ...params,
                resource_type_ids: [this.selectedFrequentResourceType.id]
              }
            }
          )
          .then(response => {
            const { data } = response.data;

            if (
              this.selectedFrequentResourceType ||
              (this.anyResourceTypes && this.anyResourceTypes.length)
            ) {
              dataToSend.resource_ids = [];
            }

            if (data && data.length) {
              dataToSend.resource_ids = data.map(item => item.id);
            }
          })
          .catch(error => {
            if (error.response) {
              errorHandler(error.response);
            }
          })
          .finally(() => {
            this.$store.commit("loader/setScreenLoading", false, {
              root: true
            });
            httpServiceAuth
              .post(`${apiEndpoints.company.declarations}${type}`, dataToSend)
              .then(() => {
                this.setSelectedSlot(null);
              })
              .catch(error => {
                if (error.response) {
                  errorHandler(error.response, this.findElement());
                }
              })
              .finally(() => {
                this.getRequiredSlots();
              });
          });
      } else {
        this.$store.commit("loader/setScreenLoading", true, { root: true });
        httpServiceAuth
          .post(`${apiEndpoints.company.declarations}${type}`, dataToSend)
          .then(() => {
            this.setSelectedSlot(null);
          })
          .catch(error => {
            if (error.response) {
              errorHandler(error.response, this.findElement());
            }
          })
          .finally(() => {
            this.$store.commit("loader/setScreenLoading", false, {
              root: true
            });
            this.getRequiredSlots();
          });
      }
    },
    displayDate(date, format) {
      if (this.timezones && this.timezones.data) {
        return displayDateWithTimezone({
          date,
          format,
          timezones: this.timezones
        });
      }
      return "";
    },
    handlePreselectedDate() {
      this.$nextTick(() => {
        // if map had selected slots preselect them
        let mapSlots = localStorage.getItem("mapSlots");
        if (mapSlots) {
          mapSlots = JSON.parse(mapSlots);
          const offset = getOffsetFromDateString(
            mapSlots.slots[0].datetime_from
          );
          const timezone = getTimezoneCodeFromOffset({
            offset,
            timezones: this.timezones.data
          });
          this.selectDate(
            DateTime.fromISO(mapSlots.slots[0].datetime_from).setZone(timezone)
          );
        } else if (this.setPlan) {
          this.selectDate(DateTime.fromISO(this.setPlan.datetime_from));
        } else {
          this.selectDate(DateTime.fromISO(DateTime.now()));
        }
      });
    },
    handlePreselectedSlots() {
      this.$nextTick(() => {
        // if map had selected slots preselect them
        let mapSlots = localStorage.getItem("mapSlots");
        if (mapSlots) {
          mapSlots = JSON.parse(mapSlots);
          this.$store.commit("plan/setSelectedSlot", mapSlots, {
            root: true
          });
          localStorage.removeItem("mapSlots");
          this.preselectedSlot = mapSlots.type;
        } else if (this.setPlan) {
          this.preselectedSlot = this.setPlan.type;
          this.$store.commit("oneClick/setSetPlan", null, { root: true });
        }
      });
    },
    backButton() {
      if (this.backLinkName && this.backLinkName === "r_plan") {
        this.selectPlanLevel(null);
      }
      this.$router.push({
        name:
          this.$route.params && this.$route.params.one_click === "true"
            ? "r_one-click"
            : this.backLinkName
      });
    },
    wsCallback(e) {
      const currentMonth = this.selectedMonth
        ? this.selectedMonth
        : DateTime.now().toFormat("yyyy-MM");
      const declarationDate = DateTime.fromISO(e.datetime_to).toFormat(
        "yyyy-MM"
      );
      if (
        currentMonth === declarationDate &&
        (e.resource_id === this.selectedResource.id ||
          e.level_id === this.selectedResource.level_id)
      ) {
        this.getRequiredSlots(true);
      }
    }
  },
  watch: {
    selectedDate(value) {
      this.$store.commit("plan/setPlanSelectedDate", value, { root: true });
    }
  },
  props: {
    backLinkName: {
      required: true,
      type: String
    },
    helpLinkName: {
      type: String
    }
  },
  mixins: [PlanMixin, helpOnlineMixin],
  components: {
    MobileScreen,
    TopHeaderMenuWrapper,
    PlansFooterMenu,
    SpaceCalendar,
    SelectSlots
  },
  beforeUnmount() {
    if (this.channel) {
      unsubscribe(this.channel);
      this.channel = null;
    }
  }
};
</script>
